export const submit = async (
	token,
	license_id,
	data_recommendation_letter_path,
	data_previous_license_path,
	data_stra_path,
	data_rule_resume_path,
	data_certificate_path,
	data_portfolio_detail_path
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}license_exam/submit`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				license_id: parseInt(license_id),
				data_recommendation_letter_path: data_recommendation_letter_path,
				data_previous_license_path: data_previous_license_path,
				data_stra_path: data_stra_path,
				data_rule_resume_path: data_rule_resume_path,
				data_certificate_path: data_certificate_path,
				data_portfolio_detail_path: data_portfolio_detail_path,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const read = async (token, id) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}license_exam/read`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				id: parseInt(id),
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const active = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}license_exam/active`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		}
	);
	const json = await response.json();
	return json;
};

export const score = async (token, id, reference_number) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}license_exam/score`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				id: parseInt(id),
				reference_number: reference_number,
			}),
		}
	);
	const json = await response.json();
	return json;
};
