import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import config from "../helpers/config";
import LeftMenuPanel from "../components/LeftMenuPanel";
import { useSelector } from "react-redux";
import { profile } from "../services/auth";
import swal from 'sweetalert';
import moment from "moment";
import { Link } from "react-router-dom";
import { updateIAIId, validateIAIId } from "../services/account";
import { isOnWhitelist, readAll } from "../services/vote";

export const Title = ({ title }) => {
    return <div style={{
        color: "#C7B37F",
        fontSize: "18px",
        marginTop: "20px",
        marginBottom: "20px",
        fontWeight: "bold"
    }}>{title}</div>
}

const Checked = () => {
    return <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="16" fill="#AA272F" stroke="#AA272F" stroke-width="3" />
        <path d="M15.5501 22.9996L9.8501 17.2996L11.2751 15.8746L15.5501 20.1496L24.7251 10.9746L26.1501 12.3996L15.5501 22.9996Z" fill="white" />
    </svg>
}

const Unchecked = () => {
    return <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="16" stroke="#AA272F" stroke-width="3" />
    </svg>
}

const renderVoteButton = (eligible, vote) => {
    const { status, can_vote } = vote;

    if (!eligible) {
        return <button className="btn btn-default" disabled>Ikuti</button>;
    }

    if (!can_vote) {
        return <button className="btn btn-default" disabled>Selesai</button>;
    }

    switch (status) {
        case "ONGOING":
            return <Link to={`/vote/${vote.id}`} className="btn btn-default">Vote</Link>;
        case "NOT_STARTED":
            return <button className="btn btn-default" disabled>Belum Dimulai</button>;
        case "ENDED":
            return <button className="btn btn-default" disabled>Terlewat</button>;
        default:
            return <button className="btn btn-default" disabled>Selesai</button>;
    }
}

const DPTCheckScreen = () => {
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [votesLoading, setVotesLoading] = useState(true);
    const [votes, setVotes] = useState([]);
    const [whiteListed, setWhitelisted] = useState(false);
    const [formIAIId, setFormIAIId] = useState(null);
    const [iaiId, setIaiId] = useState(null);
    const [expiredDate, setExpiredDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showFormIAIId, setShowFormIAIId] = useState(false);
    const [checkIAIIdLoading, setCheckIAIIdLoading] = useState(false);
    const [formIAIIdError, setFormIAIIdError] = useState(null);

    const [firstNumber, setFirstNumber] = useState('');
    const [secondNumber, setSecondNumber] = useState('');
    const [thirdNumber, setThirdNumber] = useState('');

    const [triggerReloadTimestamp, setTriggerReloadTimestamp] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Cek DPT";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getProfile(token) {
            setIsLoading(true);
            const res = await profile(token);
            if (res.error === null || res.error === "") {
                // remove spaces and then trim
                const trimmedIaiId = res.data.iai_id.replace(/\s/g, '').trim();
                setIaiId(res.data.iai_id);
                setFormIAIId(trimmedIaiId);
            }
            setIsLoading(false);
        }

        async function getVotes(token) {
            setVotesLoading(true);
            const [isOnWhitelistRes, votesRes] = await Promise.all([isOnWhitelist(token), readAll(token)]);
            if (votesRes.error === null || votesRes.error === "") {
                setVotes(votesRes.data);
            }
            if (isOnWhitelistRes.error === null || isOnWhitelistRes.error === "") {
                setWhitelisted(true);
            } else {
                setWhitelisted(false);
            }
            setVotesLoading(false);
        }

        if (auth === null || auth.token === null) return;

        getProfile(auth.token);
        getVotes(auth.token);
    }, [auth, triggerReloadTimestamp]);

    const isActiveDPT = useMemo(() => {
        const isExpired = account.is_expired;
        if (isExpired) return false;

        if (account.membership === null || typeof account.membership === 'undefined') return false;

        const expiredDateTime = account.membership.expired;
        if (expiredDateTime === null || expiredDateTime === '0000-00-00 00:00:00') return false;

        setExpiredDate(expiredDateTime);

        // check if more than 30 december 2024
        const expiredDate = new Date(expiredDateTime);
        const dptDate = new Date(2024, 11, 30);
        return expiredDate > dptDate;
    }, [account]);

    const isIAIIDValid = useMemo(() => {
        if (iaiId === null) {
            return false;
        };

        // remove spaces and then trim
        const trimmedIaiId = iaiId.replace(/\s/g, '').trim();

        // should be 11 or 12 characters
        if (trimmedIaiId.length !== 11 && trimmedIaiId.length !== 12) {
            setShowFormIAIId(true);
            return false;
        };

        return true;
    }, [iaiId]);

    const isDPTJakarta = useMemo(() => {
        if (!isIAIIDValid) return false;

        const trimmedIaiId = iaiId.replace(/\s/g, '').trim();

        // check last 3 digits is 100
        const lastThreeDigits = trimmedIaiId.substring(trimmedIaiId.length - 3);
        if (lastThreeDigits !== '100') return false;

        return true;
    }, [iaiId, isIAIIDValid]);

    useEffect(() => {
        // pad first number with 0 until 5 characters
        const paddedFirstNumber = firstNumber.padStart(5, '0');

        setFormIAIId(`${paddedFirstNumber}${secondNumber}${thirdNumber}`);
    }, [firstNumber, secondNumber, thirdNumber]);

    // debounce on newIaiId change for 2 seconds
    useEffect(() => {
        if (formIAIId === null) return;

        const handleValidateIAIId = async (newIaiId) => {
            const res = await validateIAIId(auth.token, newIaiId);
            if (res.error === null || res.error === "") {
                setFormIAIIdError(null);
            } else {
                setFormIAIIdError(res.error);
            }
            setCheckIAIIdLoading(false);
        }

        setCheckIAIIdLoading(true);

        const timer = setTimeout(() => {
            handleValidateIAIId(formIAIId);
        }, 1000);

        return () => clearTimeout(timer);
    }, [formIAIId, auth]);

    const handleUpdateIAIId = async (e) => {
        e.preventDefault();

        const res = await updateIAIId(auth.token, formIAIId);
        if (res.error === null || res.error === "") {
            setShowFormIAIId(false);

            // remove spaces and then trim
            const trimmedIaiId = formIAIId.replace(/\s/g, '').trim();

            setIaiId(trimmedIaiId);

            setTriggerReloadTimestamp(new Date().getTime());

            // show toast
            await swal({
                title: "Berhasil",
                text: "Nomor Keanggotaan IAI berhasil disimpan",
            });
        } else {
            setFormIAIIdError(res.error);
        }
    }

    const handleTextNumberChange = (value, max, setter, prevenLeadingZero = false) => {
        // validate number
        if (isNaN(value)) return;

        // prevent spaces, dots, commas
        if (value.includes(' ') || value.includes('.') || value.includes(',')) return;

        // prevent leading zero
        if (prevenLeadingZero && value.startsWith('0')) return;

        // maximum 3 characters
        if (value.length > max) return;

        // set value
        setter(value);
    }

    const titleStatus = useMemo(() => {
        if (!whiteListed) {
            return "Anda Tidak Terdaftar Sebagai Pemilih Tetap";
        }

        if (!isActiveDPT) {
            return "Masa Keanggotaan Anda Tidak Memenuhi Syarat";
        }

        if (!isDPTJakarta) {
            return "Anda Bukan Anggota IAI Jakarta";
        }

        return "Anda Memenuhi syarat untuk berpartisipasi dalam E-Vote";
    }, [whiteListed, isActiveDPT, isDPTJakarta]);

    const subTitleStatus = useMemo(() => {
        if(!whiteListed || !isActiveDPT || !isDPTJakarta) {
            return "Mohon Maaf, Anda Belum Memenuhi Syarat untuk Terdaftar dalam Daftar Pemilih Tetap (DPT)";
        }

        return "Dengan senang hati menginformasikan bahwa Anda telah memenuhi persyaratan dan  terdaftar sebagai pemilih tetap.";
    }, [whiteListed, isActiveDPT, isDPTJakarta]);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={0} />
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <div className="box text-center" style={{
                            padding: '50px'
                        }}>
                            <h5><b>
                                {titleStatus}
                            </b></h5>
                            <p>{ subTitleStatus }</p>
                        </div>
                        <Title title="Persyaratan menjadi DPT" />
                        <div className="box p-4">
                            <Row>
                                <Col sm={10}>
                                    <b>Terdaftar sebagai DPT E-Vote</b>
                                    <p>Untuk E-Vote, hanya Anggota IAI Jakarta yang namanya tercantum dalam Daftar Pemilih Tetap (DPT) yang dilampirkan oleh IAI Nasional yang dapat mengikuti E-Vote. </p>
                                    <a href="https://drive.google.com/file/d/15CIYNCdrJlUFAFuGAu4-mAY9Bmylujb2/view?usp=sharing" target="_blank" rel="noreferrer">Cek Daftarnya disini.</a>
                                </Col>
                                <Col sm={2} className="text-end">
                                    {whiteListed ? <Checked /> : <Unchecked />}
                                </Col>
                            </Row>
                        </div>
                        <div className="box p-4 mt-4">
                            <Row>
                                <Col sm={10}>
                                    <b>Merupakan anggota IAI Jakarta</b>
                                    <p>Hanya Anggota IAI Jakarta yang dapat berpartisipasi pada Pemilihan Ketua IAI Jakarta </p>
                                </Col>
                                <Col sm={2} className="text-end">
                                    {isDPTJakarta ? <Checked /> : <Unchecked />}
                                </Col>
                            </Row>
                        </div>
                        <div className="box p-4 mt-4">
                            <Row>
                                <Col sm={10}>
                                    <b>Merupakan anggota aktif IAI Jakarta</b>
                                    <p>Menjadi Peserta Aktif dengan masa akhir keanggotaan lebih dari sama dengan 31 Desember 2024</p>
                                    <p>Masa akhir keanggotaan Anda : <span style={{
                                        color: 'red'
                                    }}>{moment(expiredDate).format('D MMMM yyyy')}</span></p>
                                </Col>
                                <Col sm={2} className="text-end">
                                    {isActiveDPT ? <Checked /> : <Unchecked />}
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4">
                            <Row style={{
                                alignItems: 'center'
                            }}>
                                <Col sm={7} style={{
                                    color: '#575757',
                                    fontSize: '14px'
                                }}>
                                    Hubungi Sekretariat IAI Jakarta apabila terdapat kesalahan data
                                </Col>
                                <Col sm={5} className="text-end">
                                    <a href="https://wa.me/6281287494656" style={{
                                        border: "1px solid #25D366",
                                        color: "#25D366",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        display: "inline-block",
                                        marginTop: "10px",
                                        textDecoration: "none"
                                    }}>Hubungi Sekretariat</a>
                                </Col>
                            </Row>
                        </div>
                        <Title title="Jadwal E-Vote" />
                        {votesLoading && <div className="text-center">Loading...</div>}
                        {!votesLoading && (
                            <>
                                {
                                    votes.length > 0 ? votes.map(vote => (<div className="box p-4 mt-4">
                                        <Row>
                                            <Col sm={10}>
                                                <h5><b>{vote.name}</b></h5>
                                                <div className="d-flex" style={{
                                                    gap: "10px"
                                                }}>
                                                    <div>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        {moment(vote.start_datetime).format('D MMMM yyyy')} - {moment(vote.end_datetime).format('D MMMM yyyy')}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    {vote.description}
                                                </div>
                                            </Col>
                                            <Col sm={2} className="text-end">
                                                {renderVoteButton(isActiveDPT && isDPTJakarta, vote)}
                                            </Col>
                                        </Row>
                                    </div>)) : (
                                        <div className="text-center">Tidak ada Jadwal</div>
                                    )
                                }
                            </>
                        )}
                    </Col>
                </Row>
            </Container>

            <Modal show={showFormIAIId} centered>
                <Modal.Body className="p-4">
                    <div className="text-center mb-4">
                        <h3><b>Mohon Lengkapi Data</b></h3>
                        <p>Masukan Nomor Kenaggotaan IAI</p>
                    </div>
                    <form onSubmit={handleUpdateIAIId}>
                        <div className="form-group mb-4">
                            {
                                formIAIIdError && (
                                    <div className="alert alert-danger mt-4">{formIAIIdError}</div>
                                )
                            }
                            <label htmlFor="iai_id" className="form-label"><b>Nomor Keanggotaan IAI</b></label>
                            <div style={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <input value={firstNumber} onChange={e => handleTextNumberChange(e.target.value, 5, setFirstNumber, true)} type="text" className="form-control" placeholder="0000" required />
                                <input value={secondNumber} onChange={e => handleTextNumberChange(e.target.value, 3, setSecondNumber)} type="text" className="form-control" placeholder="000" required />
                                <input value={thirdNumber} onChange={e => handleTextNumberChange(e.target.value, 4, setThirdNumber)} type="text" className="form-control" placeholder="000" required />
                            </div>
                            <div style={{
                                fontSize: '12px',
                                color: '#575757',
                            }}>
                                Masukan Nomor Keanggotaan IAI Sesuai pada Kartu Keanggotaan
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <button disabled={checkIAIIdLoading} className="btn" type="button" onClick={() => setShowFormIAIId(false)}>Isi nanti</button>
                            <button disabled={checkIAIIdLoading || formIAIIdError} className="btn btn-default" type="submit">
                                {checkIAIIdLoading ? 'Mengecek...' : 'Simpan'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DPTCheckScreen;