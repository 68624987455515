import { Link } from "react-router-dom";

const LicenseExamBox = ({ exam }) => {
	const { title, startDate, endDate, description, link } = exam;

	const RenderLink = () => {
		if (!link) {
			return (
				<button className="btn btn-default" disabled>
					Ikuti
				</button>
			);
		}

		// if link starts with http or https, open in new tab
		if (link.startsWith("http")) {
			return (
				<a href={link} target="_blank" rel="noreferrer">
					<button className="btn btn-default">Ikuti</button>
				</a>
			);
		}

		return (
			<Link to={link}>
				<button className="btn btn-default">Ikuti</button>
			</Link>
		);
	};

	return (
		<div className="box p-3 mb-3">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div>
					<div
						style={{
							fontWeight: "800",
							fontSize: "17px",
							marginBottom: "8px",
						}}
					>
						{title}
					</div>
					<div
						style={{
							color: "#575757",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							gap: "4px",
							marginBottom: "8px",
						}}
					>
						<svg
							width="23"
							height="24"
							viewBox="0 0 23 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.70395 7V3M15.2209 7V3M6.76433 11H16.1605M4.8851 21H18.0398C19.0776 21 19.919 20.1046 19.919 19V7C19.919 5.89543 19.0776 5 18.0398 5H4.8851C3.84722 5 3.00586 5.89543 3.00586 7V19C3.00586 20.1046 3.84722 21 4.8851 21Z"
								stroke="#C4C4C4"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div>
							{startDate} - {endDate}
						</div>
					</div>
					<div>{description}</div>
				</div>
				<div>
					<RenderLink />
				</div>
			</div>
		</div>
	);
};

export default LicenseExamBox;
