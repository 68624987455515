import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const EBookBox = (props) => {
	const { ebook, widthPerItem } = props;
	const navigate = useNavigate();

	const ebookImage =
		ebook.cover !== null && ebook.cover !== ""
			? ebook.cover
			: "https://via.placeholder.com/300x200";

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{ebook.title}
		</Tooltip>
	);

	return (
		<div style={{}}>
			<div
				className="image-box"
				style={{
					padding: "0px",
					marginBottom: "20px",
					overflow: "hidden",
				}}
			>
				<div
					onClick={() => navigate("/ebook/" + ebook.slug)}
					style={{
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<img
						src={ebookImage}
						alt=""
						style={{
							width: "100%",
							height: "auto",
							objectFit: "cover",
							borderRadius: "10px",
							transition: "transform 0.2s",
						}}
					/>
				</div>
				<div
					style={{
						marginTop: "14px",
					}}
				>
					<p
						style={{
							fontSize: 18,
							fontWeight: "bold",
							marginBottom: "10px",
						}}
					>
						<OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip}
						>
							<div
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									width: widthPerItem,
								}}
							>
								<Link
									to={"/ebook/" + ebook.slug}
									className="link-dark"
									style={{
										textDecoration: "none",
									}}
								>
									{ebook.title}
								</Link>
							</div>
						</OverlayTrigger>
					</p>
					<div
						style={{
							fontSize: "10pt",
							marginBottom: "5px",
							fontWeight: "bold",
							marginTop: "10px",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "5px",
							}}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
									stroke="#AA272F"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>

							{moment(ebook.created_at).format("MMM YYYY")}
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "5px",
							}}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
									fill="#AA272F"
								/>
							</svg>
							{ebook.total_download}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EBookBox;
