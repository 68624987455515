import React, { useEffect } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import LicenseExams from "../components/license_exams/LicenseExams";

const LicenseExamScreen = () => {
	//change title
	useEffect(() => {
		document.title = config.documentTitle + " - Ujian Lisensi";

		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="default-nav-mt">
			<Container>
				<Row>
					<Col sm={12} md={12} lg={4} className="mb-4">
						<LeftMenuPanel activeIndex={10} />
					</Col>
					<Col sm={12} md={12} lg={8}>
						<LicenseExams />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LicenseExamScreen;
