// LoadingProgressBar.jsx
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

export const LoadingProgressBar = ({
    now = 0,
    variant = 'primary',
    animated = true,
    striped = true,
    showLabel = true,
    height,
    className = '',
    autoIncrement = false,
    incrementInterval = 200,
    incrementValue = 1
}) => {
    const [progress, setProgress] = useState(now);

    useEffect(() => {
        setProgress(now);
    }, [now]);

    useEffect(() => {
        let timer;
        if (autoIncrement && progress < 100) {
            timer = setInterval(() => {
                setProgress(prev => {
                    const next = prev + incrementValue;
                    return next > 100 ? 100 : next;
                });
            }, incrementInterval);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [autoIncrement, progress, incrementInterval, incrementValue]);

    const styles = {
        progressBar: {
            height: height || '20px',
            borderRadius: '4px',
            transition: 'width 0.3s ease-in-out'
        }
    };

    return (
        <ProgressBar
            now={progress}
            variant={variant}
            animated={animated}
            striped={striped}
            label={showLabel ? `${Math.round(progress)}%` : undefined}
            style={styles.progressBar}
            className={className}
        />
    );
};