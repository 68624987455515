import React, { useState } from "react";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { checkUser } from "../services/auth";
import Loading from "./Loading";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getTempEmail, saveTempEmail } from "../helpers/storage";
import { setRedirectUrl } from "../redux/slicers/authSlice";

const InputEmailModal = ({
	showModal,
	onClose,
	redirectUrl,
	onLoginWithGoogle,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [email, setEmail] = useState(getTempEmail() ?? "");
	const [loading, setLoading] = useState(false);

	const checkEmail = async (email) => {
		setLoading(true);
		try {
			const res = await checkUser(email);

			if (!res.user) {
				saveTempEmail(email);
				navigate(redirectUrl);
				return;
			}

			handleLoginRedirection(res.user);
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	const handleLoginRedirection = (user) => {
		if (!user.is_active) {
			swal(
				"Perhatian",
				"Akun anda belum aktif, silahkan cek email anda untuk melakukan aktivasi",
				"warning"
			);
			return;
		}

		if (user.register_from === "Google") {
			onLoginWithGoogle();
			return;
		}

		saveTempEmail(email);
		dispatch(setRedirectUrl(redirectUrl));
		navigate("/login", { state: { email: email } });
	};

	const submit = (e) => {
		e.preventDefault();
		checkEmail(email);
	};

	return (
		<Modal size="md" show={showModal} onHide={onClose}>
			<Modal.Body>
				<form onSubmit={submit}>
					<div
						style={{
							fontSize: "24px",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						Masukan Alamat Email
					</div>
					<div
						className="alert alert-danger"
						style={{
							borderLeft: "5px solid #B15151",
							marginTop: "10px",
							color: "#1c1c1c",
						}}
					>
						<b>Perhatian</b>
						<div>
							Bagi Anggota IAI yang sudah memiliki Akun di Website IAI Jakarta,
							harap mengisi Alamat Email yang terdaftar agar KUM dapat tercatat
							pada akun Anda.
						</div>
					</div>
					<div className="form-group">
						<label>
							<b>Alamat Email</b>
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="Email..."
							disabled={loading}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div
						style={{
							fontSize: "12px",
							color: "#575757",
						}}
					>
						Gunakan Email Aktif untuk dapat melakukan absensi dan mendapatkan
						Sertifikat
					</div>
					<div
						style={{
							display: "flex",
							gap: "10px",
							marginTop: "20px",
						}}
					>
						<button
							onClick={onClose}
							disabled={loading}
							type="button"
							style={{
								width: "100%",
							}}
							className="btn btn-default-outline"
						>
							Batal
						</button>
						<button
							type="submit"
							disabled={loading}
							style={{
								width: "100%",
							}}
							className="btn btn-default"
						>
							{loading ? <Loading /> : "Lanjut"}
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default InputEmailModal;
