import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../services/account";
import swal from "sweetalert";
import Loading from "./../Loading";
import { setIsPasswordSet } from "../../redux/slicers/accountSlice";
import { useNavigate } from "react-router";
import {
	getEventRedirectUrl,
	removeEventRedirectUrl,
} from "../../helpers/storage";

const SetPassword = () => {
	const auth = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const resetForm = () => {
		setNewPassword("");
		setConfirmPassword("");
	};

	const submit = async (e) => {
		e.preventDefault();

		setLoading(true);

		const res = await changePassword(
			auth.token,
			"",
			newPassword,
			confirmPassword
		);

		if (res.error === null) {
			swal("Success!", "Berhasil mengubah password", "success");
			dispatch(setIsPasswordSet(true));
			resetForm();

			const eventRedirecUrl = getEventRedirectUrl();

			// delete redirectUrl
			removeEventRedirectUrl();

			// redirect to redirectUrl
			navigate(eventRedirecUrl ?? "/dashboard");
		} else {
			swal("Error!", res.error, "error");
		}

		setLoading(false);
	};

	return (
		<>
			<form onSubmit={submit}>
				<div className="mb-3">
					<label>Password Baru</label>
					<input
						disabled={loading}
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						type="password"
						className="form-control"
						placeholder="Password Baru Anda"
						required
					/>
				</div>
				<div className="mb-3">
					<label>Konfirmasi Password Baru</label>
					<input
						disabled={loading}
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						type="password"
						className="form-control"
						placeholder="Konfirmasi Password Baru Anda"
						required
					/>
				</div>
				<div className="mb-3 text-end">
					<button
						type="submit"
						disabled={loading}
						className="btn btn-default"
						onClick={() => {}}
					>
						{loading ? <Loading /> : "Setel Kata Sandi"}
					</button>
				</div>
			</form>
		</>
	);
};

export default SetPassword;
