import React from "react";
import LicenseExamBox from "./LicenseExamBox";

const LicenseExams = () => {
	return (
		<>
			<div
				style={{
					color: "#221F1F",
				}}
			>
				<span
					style={{
						color: "#C7B37F",
						fontWeight: "bold",
					}}
				>
					Dashboard
				</span>{" "}
				/ Ujian Lisensi
			</div>

			<div
				className="box p-5"
				style={{
					marginTop: "20px",
					color: "#221F1F",
					textAlign: "center",
				}}
			>
				<div
					style={{
						fontSize: "24px",
						fontWeight: "bold",
						marginBottom: "10px",
					}}
				>
					Ujian Lisensi
				</div>
				<p>
					Anda dapat mempersiapkan diri untuk mendapatkan lisensi arsitek yang
					sesuai dengan pengalaman dan kompetensi Anda
				</p>
			</div>

			<div
				style={{
					marginTop: "40px",
				}}
			>
				<div
					style={{
						color: "#C7B37F",
						fontSize: "18px",
						fontWeight: "bold",
						marginBottom: "20px",
					}}
				>
					Jadwal Ujian Lisensi
				</div>

				<LicenseExamBox
					exam={{
						title: "Ujian Lisensi Batch 1",
						startDate: "3 September 2024",
						endDate: "13 September 2024",
						description: "Deskripsi muncul di sini",
						link: "/license-exam-form",
					}}
				/>
				<LicenseExamBox
					exam={{
						title: "Ujian Lisensi Batch 2",
						startDate: "3 September 2024",
						endDate: "13 September 2024",
						description: "Deskripsi muncul di sini",
					}}
				/>
			</div>
		</>
	);
};

export default LicenseExams;
