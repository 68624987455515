import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { search } from "./../services/ebook";
import swal from "sweetalert";
import { Container, Button, Row, Col } from "react-bootstrap";
import config from "../helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EBookBox from "../components/ebook/EBookBox";

const EbookListScreen = () => {
	const auth = useSelector((state) => state.auth);

	const [ebookList, setEbookList] = useState([]);
	const [page, setPage] = useState(1);
	const [reset, setReset] = useState(true);
	const [isContainMore, setIsContainMore] = useState(true);
	const [keywordTemp, setKeywordTemp] = useState("");
	const [keyword, setKeyword] = useState("");

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// listen on window resize
	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const singleRow = useMemo(() => {
		if (windowWidth > 1200) return true;

		return false;
	}, [windowWidth]);

	const widthPerItem = useMemo(() => {
		if (singleRow) {
			const pad = 10 * ebookList.length;

			return `${Math.round(windowWidth / ebookList.length - pad)}px`;
		}

		return "100%";
	}, [singleRow, windowWidth, ebookList]);

	console.log("debug", widthPerItem);

	//change title
	useEffect(() => {
		document.title = config.documentTitle + " - Ebook";

		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		async function getEvent() {
			const res = await search(auth.token, keyword, page);
			if (res.error === null) {
				if (reset) {
					setEbookList(res.data);
				} else {
					//append
					setEbookList((newsList) => [...newsList, ...res.data]);
				}

				if (res.data.length < 1) {
					setIsContainMore(false);
				} else {
					setIsContainMore(true);
				}
			} else {
				swal("Error!", res.error.message, "error");
			}
		}

		getEvent();
	}, [auth, page, reset, keyword]);

	const loadMore = () => {
		setReset(false);
		//set last id
		setPage(page + 1);
	};

	const filter = () => {
		setReset(true);
		setPage(1);
		setKeyword(keywordTemp);
	};

	return (
		<div className="default-nav-mt">
			<Container>
				<div
					className="row"
					style={{
						alignItems: "center",
					}}
				>
					<div className="col-sm-12 col-md-6 col-lg-9 mt-1">
						<div
							style={{
								color: "#AA272F",
								fontSize: "32px",
								fontFamily: "Noto Serif JP",
							}}
						>
							E-Book Illustrated Guideline to Jakarta Regulations
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-3 text-end">
						<div
							style={{
								display: "inline-block",
							}}
						>
							<input
								type="text"
								value={keywordTemp}
								onChange={(e) => setKeywordTemp(e.target.value)}
								className="form-control"
								placeholder="Cari E-Book"
							/>
						</div>
						<button onClick={filter} className="btn btn-default ms-2">
							<FontAwesomeIcon icon={faSearch} />
						</button>
					</div>
				</div>
				<div
					className={`mt-5${singleRow ? "" : " row"}`}
					style={
						singleRow
							? {
									display: "flex",
									justifyContent: "center",
									gap: "10px",
							  }
							: {}
					}
				>
					{ebookList.map((ebook, index) => (
						<div
							className={`${singleRow ? "" : "col-xs-6 col-sm-6 col-md-4"}`}
							style={
								singleRow
									? {
											flex: 1,
											boxSizing: "border-box",
									  }
									: {}
							}
						>
							<EBookBox widthPerItem={widthPerItem} ebook={ebook} />
						</div>
					))}
				</div>
				<div className="text-center" hidden={!isContainMore}>
					<Button variant="danger" className="btn-default" onClick={loadMore}>
						Muat Lebih Banyak
					</Button>
				</div>
			</Container>
		</div>
	);
};

export default EbookListScreen;
