import React, { useEffect, useRef, useState } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//firebase
import { firebaseInit } from "./../helpers/firebase";
import {
	getAuth,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
} from "firebase/auth";
import { getToken, getMessaging } from "firebase/messaging";

//services
import {
	loginGoogle,
	loginFacebook,
	getUser,
	login,
	loginOtp,
	checkOtp as checkOtpAPI,
} from "./../services/auth";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "./../redux/slicers/authSlice";
import { setAccount } from "./../redux/slicers/accountSlice";
import config from "../helpers/config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faEyeSlash,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
	getLoginWithGoogle,
	getTempEmail,
	removeLoginWithGoogle,
	removeTempEmail,
	saveTempEmail,
} from "../helpers/storage";

//init firebase
firebaseInit();
const auth = getAuth();
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const LoginScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let location = useLocation();

	const information = useSelector((state) => state.information);

	const [username, setUsername] = useState(getTempEmail() ?? "");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [user, setUser] = useState(null);
	const [password, setPassword] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [loading, setLoading] = useState(false);

	const [loadingToken, setLoadingToken] = useState(false);
	const [fcmToken, setFCMToken] = useState(null);

	const loginWithGoogleButtonRef = useRef(null);

	const [loginWithPhoneNumber, setLoginWithPhoneNumber] = useState(false);
	const [isOTPInvoked, setIsOTPInvoked] = useState(false);
	const [otp, setOTP] = useState("");

	const redirect = () => {
		//check if has location
		if (location !== null) {
			if (location.pathname !== "/login") {
				navigate(location.pathname);
			}
		}
	};

	//change title
	useEffect(() => {
		document.title = config.documentTitle + " - Masuk";

		window.scrollTo(0, 0);

		setLoadingToken(true);

		//listen to notification push from firebase
		const messaging = getMessaging();
		// Get registration token. Initially this makes a network call, once retrieved
		// subsequent calls to getToken will return from cache.
		getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
			.then((currentToken) => {
				if (currentToken) {
					setFCMToken(currentToken);
					setLoadingToken(false);
				} else {
					setLoadingToken(false);
					// Show permission request UI
					console.log(
						"No registration token available. Request permission to generate one."
					);
					// ...
				}
			})
			.catch((err) => {
				setLoadingToken(false);
				console.log("An error occurred while retrieving token. ", err);
				// ...
			});

		// check if has login with google
		if (getLoginWithGoogle() === "true") {
			removeLoginWithGoogle();

			swal(
				"Perhatian",
				'Akun anda terindikasi terhubung dengan Google, silahkan klik "Masuk dengan Google"',
				"info"
			);
		}
	}, []);

	const loginWithGoogle = () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				setLoading(true);

				// This gives you a Google Access Token. You can use it to access the Google API.
				//const credential = GoogleAuthProvider.credentialFromResult(result);
				//const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;

				//login google API
				const id_token = result._tokenResponse.oauthIdToken;
				loginGoogle(id_token, user.photoURL, user.displayName).then((res) => {
					setLoading(false);
					if (res.token != null) {
						removeTempEmail();
						//set account
						dispatch(setAccount(res.data));
						//login success
						dispatch(
							setAuthentication({
								isAuthenticated: true,
								token: res.token,
							})
						);

						redirect();
					} else {
						//login failed
						alert(res.error);
					}
				});
			})
			.catch((error) => {
				// Handle Errors here.
				//const errorCode = error.code;
				//const errorMessage = error.message;
				// The email of the user's account used.
				//const email = error.email;
				// The AuthCredential type that was used.
				//const credential = GoogleAuthProvider.credentialFromError(error);

				setLoading(false);
				console.log(error);
			});
	};

	const loginWithFacebook = () => {
		signInWithPopup(auth, facebookProvider)
			.then((result) => {
				setLoading(true);

				// This gives you a Google Access Token. You can use it to access the Google API.
				//const credential = GoogleAuthProvider.credentialFromResult(result);
				//const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;

				//login google API
				const accessToken = result._tokenResponse.oauthAccessToken;
				const uid = user.providerData[0].uid;
				loginFacebook(uid, accessToken, user.photoURL, user.displayName).then(
					(res) => {
						setLoading(false);

						if (res.token != null) {
							removeTempEmail();
							//set account
							dispatch(setAccount(res.data));
							//login success
							dispatch(
								setAuthentication({
									isAuthenticated: true,
									token: res.token,
								})
							);
						} else {
							//login failed
							alert(res.error);
						}
					}
				);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const getUserFromUsername = async () => {
		//validate username
		if (username.length < 3) {
			swal("Error!", "Username minimal 3 karakter!", "error");
			return;
		}

		const res = await getUser(username);
		if (res.error === null) {
			setUser(res.data);
		} else {
			swal("Error!", res.error, "error");
		}
	};

	const submitLogin = async (e) => {
		if (e !== null) {
			e.preventDefault();
		}

		//validate username
		if (username.length < 3) {
			swal("Error!", "Username minimal 3 karakter!", "error");
			return;
		}

		//validate password
		if (username.length < 1) {
			swal("Error!", "Password tidak boleh kosong", "error");
			return;
		}

		saveTempEmail(username);

		setLoading(true);

		const res = await login(username, password);
		if (res.error === null) {
			removeTempEmail();

			//set account
			dispatch(setAccount(res.data));
			//login success
			dispatch(
				setAuthentication({
					isAuthenticated: true,
					token: res.token,
				})
			);
		} else {
			swal("Error!", res.error, "error");
		}

		setLoading(false);
	};

	const submitLoginOTP = async () => {
		//validate username
		if (phoneNumber.length < 3) {
			swal("Error!", "Nomor Handphone minimal 3 angka!", "error");
			return;
		}

		setLoading(true);

		const res = await loginOtp(phoneNumber);
		if (res.error === null) {
			setIsOTPInvoked(true);
		} else {
			swal("Error!", res.error, "error");
		}

		setLoading(false);
	};

	const checkOTP = async () => {
		//validate username
		if (phoneNumber.length < 3) {
			swal("Error!", "Nomor Handphone minimal 3 angka!", "error");
			return;
		}

		if (otp.length !== 6) {
			swal("Error!", "Kode OTP harus 6 digit", "error");
			return;
		}

		setLoading(true);

		const res = await checkOtpAPI(phoneNumber, otp);
		if (res.error === null) {
			//set account
			dispatch(setAccount(res.data));
			//login success
			dispatch(
				setAuthentication({
					isAuthenticated: true,
					token: res.token,
				})
			);
		} else {
			swal("Error!", res.error, "error");
		}

		setLoading(false);
	};

	return (
		<>
			<div className="login-container">
				<div
					className="login-image d-none d-md-none d-xl-block d-xxl-block p-3"
					style={{
						backgroundImage: `url(/img/login.png)`,
						backgroundSize: "cover",
					}}
				>
					<img
						onClick={() => navigate("/")}
						style={{
							cursor: "pointer",
						}}
						src={information.white_logo}
						className="icon"
					/>
				</div>
				<div className="login-content">
					<img
						onClick={() => navigate("/")}
						style={{
							width: "150px",
							cursor: "pointer",
						}}
						src={information.main_logo}
						className="m-4 d-block d-md-block d-xl-none d-xxl-none"
					/>
					<div className="text-end p-5 d-none d-md-none d-xl-block d-xxl-block">
						Belum memiliki akun?{" "}
						<Link to="/register" className="def-link">
							Daftar
						</Link>
					</div>
					{loadingToken ? (
						<Loading />
					) : (
						<Container>
							<Row className="justify-content-center">
								<Col
									sm={{
										span: 12,
									}}
									md={{
										span: 10,
									}}
									lg={{
										span: 10,
									}}
									xl={{
										span: 10,
									}}
									xxl={{
										span: 6,
									}}
								>
									<div className="pb-5 p-4">
										<div className="alert alert-success">
											<FontAwesomeIcon icon={faInfoCircle} className="me-2" />
											Gunakan email yang terdaftar pada akun IAI Jakarta
										</div>
										<div
											style={{
												fontWeight: "bold",
												fontSize: "14pt",
											}}
										>
											Masuk ke IAI Jakarta
										</div>
										<div className="d-flex mt-3">
											<button
												ref={loginWithGoogleButtonRef}
												onClick={() => loginWithGoogle()}
												className="btn-google me-3"
											>
												<img src="/img/google.png" />
												Masuk dengan Google
											</button>
											<img
												onClick={() => loginWithFacebook()}
												src="/img/btn-fb.png"
												style={{
													width: "50px",
													height: "50px",
													cursor: "pointer",
												}}
											/>
										</div>
										<div className="mt-5 mb-5">
											<div className="lined">
												<span>Atau</span>
											</div>
										</div>
										<form onSubmit={submitLogin}>
											<div className="row mb-3" hidden={loginWithPhoneNumber}>
												<div className="mt-3">
													<div className="row">
														<div className="col-6">
															<label>
																<b>Email / Username</b>
															</label>
														</div>
														<div className="col-6 text-end">
															<a
																href="#!"
																hidden={true}
																onClick={() => setLoginWithPhoneNumber(true)}
																className="def-link"
															>
																Masuk Lewat Nomor HP?
															</a>
														</div>
													</div>
													<input
														disabled={loading}
														type="text"
														value={username}
														onChange={(e) => setUsername(e.target.value)}
														placeholder=""
														className="form-control"
													/>
												</div>
												<div className="mt-3">
													<div className="row">
														<div className="col-6">
															<label>
																<b>Kata Sandi</b>
															</label>
														</div>
														<div className="col-6 text-end">
															<Link to="/forgot-password" className="def-link">
																Lupa Kata Sandi?
															</Link>
														</div>
													</div>
													<input
														maxLength={20}
														disabled={loading}
														type={passwordType}
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														className="form-control"
														placeholder=""
													/>
													<span
														onClick={() => {
															setPasswordType(
																passwordType === "password"
																	? "text"
																	: "password"
															);
														}}
														style={{
															cursor: "pointer",
															marginTop: "-30px",
															float: "right",
															marginRight: "10px",
														}}
													>
														<FontAwesomeIcon
															icon={
																passwordType === "password" ? faEye : faEyeSlash
															}
														/>
													</span>
												</div>
												<div className="mt-5">
													<button
														disabled={loading}
														type="submit"
														className="btn btn-default mt-3"
														style={{
															width: "100%",
														}}
													>
														{loading ? <Loading /> : "Masuk"}
													</button>
												</div>
											</div>
										</form>
										<div className="row mb-3" hidden={!loginWithPhoneNumber}>
											<div hidden={isOTPInvoked}>
												<div className="mt-3">
													<div className="row">
														<div className="col-6">
															<label>
																<b>Nomor Handphone</b>
															</label>
														</div>
														<div className="col-6 text-end">
															<a
																href="#!"
																onClick={() => setLoginWithPhoneNumber(false)}
																className="def-link"
															>
																Masuk Dengan Email/Username
															</a>
														</div>
													</div>
													<input
														disabled={loading}
														type="tel"
														value={phoneNumber}
														onChange={(e) => setPhoneNumber(e.target.value)}
														placeholder=""
														className="form-control"
													/>
												</div>
												<div className="mt-5">
													<button
														disabled={loading}
														className="btn btn-default mt-3"
														onClick={() => submitLoginOTP()}
														style={{
															width: "100%",
														}}
													>
														{loading ? <Loading /> : "Masuk"}
													</button>
												</div>
											</div>
											<div hidden={!isOTPInvoked}>
												<div className="mt-3">
													<div className="row">
														<div className="col-6">
															<label>
																<b>Masukan Kode OTP yang kami kirimkan</b>
															</label>
														</div>
														<div className="col-6 text-end">
															<a
																href="#!"
																onClick={() => setLoginWithPhoneNumber(false)}
																className="def-link"
															>
																Masuk Dengan Email/Username
															</a>
														</div>
													</div>
													<input
														disabled={loading}
														type="tel"
														value={otp}
														onChange={(e) => setOTP(e.target.value)}
														placeholder=""
														className="form-control"
													/>
												</div>
												<div className="mt-5">
													<button
														disabled={loading}
														className="btn btn-default mt-3"
														onClick={() => checkOTP()}
														style={{
															width: "100%",
														}}
													>
														{loading ? <Loading /> : "Masuk"}
													</button>
												</div>
											</div>
										</div>
										<div className="text-center p-5 d-block d-md-block d-xl-none d-xxl-none">
											Belum memiliki akun?{" "}
											<Link to="/register" className="def-link">
												Daftar
											</Link>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					)}
				</div>
			</div>
		</>
	);
};

export default LoginScreen;
