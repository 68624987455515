import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
	name: "account",
	initialState: {
		name: "",
		avatar_url: "",
		email_address: "",
		refresh_detail: null,
		membership: null,
		is_admin: false,
		is_expired: false,
		is_age_priviledge: false,
		is_password_set: null,
	},
	reducers: {
		setAccount: (state, action) => {
			state.name = action.payload.first_name + " " + action.payload.last_name;
			state.avatar_url = action.payload.avatar;
			state.email_address = action.payload.email_address;
			state.membership = action.payload.membership;
			state.is_admin = action.payload.is_admin == "1" ? true : false;
			state.is_expired = action.payload.is_expired;
			state.is_age_priviledge = action.payload.is_age_priviledge;
			state.is_password_set = action.payload.is_password_set ?? true;
		},
		setIsPasswordSet: (state, action) => {
			state.is_password_set = action.payload;
		},
		setRefreshDetail: (state) => {
			state.refresh_detail = new Date();
		},
	},
});

// Action creators are generated for each case reducer function
export const { setAccount, setRefreshDetail, setIsPasswordSet } =
	accountSlice.actions;

export default accountSlice.reducer;
