export const loginGoogle = async (id_token, avatar_url, first_name) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/login_google`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				id_token: id_token,
				avatar_url: avatar_url,
				first_name: first_name,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const loginFacebook = async (
	uid,
	access_token,
	avatar_url,
	first_name
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/login_facebook`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				uid: uid,
				access_token: access_token,
				avatar_url: avatar_url,
				first_name: first_name,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const register = async (
	first_name,
	last_name,
	email_address,
	username,
	password,
	confirm_password
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/register`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				first_name: first_name,
				last_name: last_name,
				email_address: email_address,
				username: username,
				password: password,
				confirm_password: confirm_password,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const sendActivationEmail = async (email_address) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/send_activation_email`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email_address: email_address,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const registerGoogle = async (avatar_url, first_name, email_address) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/register_google`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				avatar_url: avatar_url,
				first_name: first_name,
				email_address: email_address,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const registerFacebook = async (
	avatar_url,
	first_name,
	email_address
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/register_facebook`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				avatar_url: avatar_url,
				first_name: first_name,
				email_address: email_address,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const profile = async (token) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}auth/profile`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
	});
	const json = await response.json();
	return json;
};

export const getUser = async (username) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/get_user`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: username,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const login = async (username, password) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			username: username,
			password: password,
		}),
	});
	const json = await response.json();
	return json;
};

export const forgotPassword = async (username) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/forgot_password`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: username,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const validateResetPassword = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/validate_reset_password`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const resetPassword = async (token, new_password, confirm_password) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/reset_password`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
				new_password: new_password,
				confirm_password: confirm_password,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const validate = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/validate`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const loginOtp = async (phone_number) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/login_otp`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				phone_number: phone_number,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const checkOtp = async (phone_number, otp) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/check_otp`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				phone_number: phone_number,
				otp: otp,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const checkUser = async (email_address) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/check_user`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email_address: email_address,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const registerPublic = async (
	first_name,
	last_name,
	email_address,
	phone_number
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}auth/register_public`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				first_name: first_name,
				last_name: last_name,
				email_address: email_address,
				phone_number: phone_number,
			}),
		}
	);
	const json = await response.json();
	return json;
};
