import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { download, read } from "./../services/ebook";
import swal from "sweetalert";
import { Container, Row, Col, Button } from "react-bootstrap";
import config from "../helpers/config";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import moment from "moment";
import Comments from "../components/ebook/Comments";
import { LoadingProgressBar } from "../components/LoadingProgressBar";

const downloadBase64Pdf = (base64String, filename) => {
    // Remove data URI prefix if exists
    const base64 = base64String.replace(/^data:application\/pdf;base64,/, '');

    // Create Blob from base64
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename || 'download.pdf';

    // Append to body, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const getFileSizeString = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
        return '0 Byte';
    }

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

};

const EbookReadScreen = () => {
    const auth = useSelector((state) => state.auth);
    const params = useParams();
    const navigate = useNavigate();
    const url = params.url;

    const [base64FileSize, setBase64FileSize] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [ebook, setEbook] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showComments, setShowComments] = useState(true);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Ebook";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getNewsDetail(url) {
            setLoading(true);

            const res = await read(url, auth.token);
            if (res.error === null) {
                setEbook(res.data);
                setBase64FileSize(res.data.base64_file_size);

                document.title = config.documentTitle + " - " + res.data.title;

                setShowComments(true);
            } else {
                swal("Error!", res.error, "error");
                // navigate("/");
            }

            setLoading(false);
        }

        getNewsDetail(url);
    }, [auth, url]);

    const onDownloadProgress = (progressEvent) => {
        const loaded = progressEvent.loaded;
        const size = base64FileSize;

        const completed = Math.round((loaded * 100) / size);

        setProgress(completed);
    };

    const handleDownload = async () => {
        setIsDownloading(true);
        const result = await download(url, auth.token, onDownloadProgress);
        if (result.error === null) {
            setIsDownloading(false);
            setProgress(0);
            downloadBase64Pdf(result.data.file, result.data.filename);
        } else {
            setIsDownloading(false);
            setProgress(0);
            swal("Error!", result.error, "error");
        }
    };

    return (
        <div className="default-nav-mt">
            <Container className="mt-5">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/ebook">E-Book</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {ebook !== null ? ebook.title : ""}
                        </li>
                    </ol>
                </nav>
                <Row>
                    <Col className="mb-3" lg={12} md={12} sm={12}>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                {ebook !== null ? (
                                    <>
                                        <div style={{
                                            backgroundColor: ebook.hexcode,
                                            padding: '20px',
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                            color: '#fff',
                                        }}>
                                            <div style={{
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}>
                                                E-Book | {moment(ebook.created_at).format('YYYY')}
                                            </div>
                                            <h3><b>{ebook.title}</b></h3>
                                        </div>
                                        <div style={{
                                            backgroundColor: '#fff',
                                            padding: '20px',
                                        }}>
                                            <div className="mt-5">
                                                <Row>
                                                    <Col lg={{
                                                        order: 1,
                                                        span: '6',
                                                    }} sm={{
                                                        order: 2,
                                                        span: '12',
                                                    }} xs={{
                                                        order: 2,
                                                        span: '12',
                                                    }}>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: ebook.body }}
                                                        />
                                                        <div style={{
                                                            marginTop: '50px',
                                                            marginBottom: '50px',
                                                        }}>
                                                            {auth.isAuthenticated ? (
                                                                <button
                                                                    style={{
                                                                        padding: '6px 20px',
                                                                        border: 'none',
                                                                        backgroundColor: isDownloading ? '#e6e6e6' : ebook.hexcode,
                                                                        width: '250px',
                                                                        textDecoration: 'none',
                                                                        color: '#fff',
                                                                        borderRadius: '5px',
                                                                    }} disabled={isDownloading} onClick={() => handleDownload()} >{isDownloading ? <Loading /> : `Unduh E-Book | ${getFileSizeString(ebook.file_size_bytes)}`}</button>
                                                            ) : (
                                                                <i>Login Terlebih Dahulu untuh Mengunduh E-Book</i>
                                                            )}

                                                            {
                                                                isDownloading && (
                                                                    <div style={{
                                                                        marginTop: '20px',
                                                                    }}>
                                                                        Mengunduh... Harap Tunggu
                                                                        <LoadingProgressBar height={10} now={progress} variant="danger" />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={{
                                                        order: 2,
                                                        span: '6',
                                                    }} sm={{
                                                        order: 1,
                                                        span: '12',
                                                    }} xs={{
                                                        order: 1,
                                                        span: '12',
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <img
                                                                src={ebook.cover}
                                                                alt={ebook.title}
                                                                style={{
                                                                    width: "100%",
                                                                    maxWidth: "400px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: "10pt",
                                                                marginBottom: "25px",
                                                                fontWeight: "bold",
                                                                marginTop: "10px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                gap: "20px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
                                                                        stroke={ebook.hexcode}
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>

                                                                {moment(ebook.created_at).format("MMM YYYY")}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                                                                        fill={ebook.hexcode}
                                                                    />
                                                                </svg>
                                                                {ebook.total_download} Unduhan
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            {ebook.references.length > 0 ? (
                                                <div>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12}>
                                                            <h4 style={{
                                                                color: '#C7B37F',
                                                                borderBottom: '1px solid #D9DDE1',
                                                                marginTop: '50px',
                                                            }}>Referensi</h4>
                                                            {
                                                                ebook.references.map((reference, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '16px',
                                                                        display: 'flex',
                                                                    }}>
                                                                        <div style={{
                                                                            width: '30px',
                                                                            fontSize: '20px',
                                                                            color: '#6A707E',
                                                                        }}>
                                                                            {index + 1}
                                                                        </div>
                                                                        <div>
                                                                            <div style={{
                                                                                fontSize: '20px',
                                                                                color: '#6A707E',
                                                                            }}>
                                                                                {reference.label}
                                                                            </div>
                                                                            <a target="_blank" href={reference.url ?? reference.file_path}>Tautan</a>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div style={{
                                            backgroundColor: ebook.hexcode,
                                            padding: '20px',
                                            borderBottomLeftRadius: '10px',
                                            borderBottomRightRadius: '10px',
                                            color: '#fff',
                                            height: '40px',
                                        }}></div>

                                        <Comments ebookId={ebook.id} />
                                    </>
                                ) : null}
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EbookReadScreen;
