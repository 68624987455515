export const search = async (
	token,
	keyword,
	page,
	condition = null,
	month = null,
	year = null
) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}event/search`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
		body: JSON.stringify({
			keyword: keyword,
			page: page,
			condition: condition,
			month: month,
			year: year,
		}),
	});
	const json = await response.json();
	return json;
};

export const upcoming = async (token, limit = 5) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}event/upcoming`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				limit: limit,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const read = async (url, token) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}event/read`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
		body: JSON.stringify({
			url: url,
		}),
	});
	const json = await response.json();
	return json;
};

export const order = async (token, event_id, payment_method, phone_number) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}event/order`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
		body: JSON.stringify({
			event_id: event_id,
			payment_method: payment_method,
			phone_number: phone_number,
		}),
	});
	const json = await response.json();
	return json;
};

export const cancel = async (token, event_order_id) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}event/cancel`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
		body: JSON.stringify({
			event_order_id: event_order_id,
		}),
	});
	const json = await response.json();
	return json;
};

export const attend = async (token, qrToken) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}event/attend`, {
		crossDomain: true,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
		body: JSON.stringify({
			token: qrToken,
		}),
	});
	const json = await response.json();
	return json;
};

export const uploadPayment = async (token, event_order_id, image) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}event/upload_payment`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				event_order_id: event_order_id,
				image: image,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const attendance = async (token, status, keyword, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}event/attendance`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				status: status,
				keyword: keyword,
				page: page,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const certificate = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}event/certificate`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const fileSource = async (token, status, keyword, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}event/file_source`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				status: status,
				keyword: keyword,
				page: page,
			}),
		}
	);
	const json = await response.json();
	return json;
};
