import axios from "axios";

export const search = async (token, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}ebook/search`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const submitComment = async (token, ebook_id, body) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}ebook/submit_comment`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ebook_id: parseInt(ebook_id),
            body: body
        })
    });
    const json = await response.json();
    return json;
}

export const comments = async (token, ebook_id, last_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}ebook/comments`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ebook_id: parseInt(ebook_id),
            last_id: parseInt(last_id)
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (slug, token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}ebook/read`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            slug: slug,
        })
    });
    const json = await response.json();
    return json;
}

export const download = async (slug, token, onDownloadProgress) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}ebook/download`, {
        slug: slug
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'Accept-Encoding': 'identity' // Request uncompressed data
        },
        onDownloadProgress: onDownloadProgress
    });
    return response.data;
}