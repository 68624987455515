const TEMP_EMAIL_KEY = "temp_email";
const EVENT_REDIRECT_URL = "event_redirect_url";
const LOGIN_WITH_GOOGLE = "login_with_google";

export const saveTempEmail = (email) => {
	localStorage.setItem(TEMP_EMAIL_KEY, email);
};

export const getTempEmail = () => {
	return localStorage.getItem(TEMP_EMAIL_KEY);
};

export const removeTempEmail = () => {
	localStorage.removeItem(TEMP_EMAIL_KEY);
};

export const saveEventRedirectUrl = (url) => {
	localStorage.setItem(EVENT_REDIRECT_URL, url);
};

export const getEventRedirectUrl = () => {
	return localStorage.getItem(EVENT_REDIRECT_URL);
};

export const removeEventRedirectUrl = () => {
	localStorage.removeItem(EVENT_REDIRECT_URL);
};

export const saveLoginWithGoogle = () => {
	localStorage.setItem(LOGIN_WITH_GOOGLE, "true");
};

export const getLoginWithGoogle = () => {
	return localStorage.getItem(LOGIN_WITH_GOOGLE);
};

export const removeLoginWithGoogle = () => {
	localStorage.removeItem(LOGIN_WITH_GOOGLE);
};
