import React, { useState } from "react";
import { registerPublic } from "../../services/auth";
import swal from "sweetalert";
import Loading from "../Loading";
import { getTempEmail, removeTempEmail } from "../../helpers/storage";

const PublicForm = ({ callback }) => {
	const [email, setEmail] = useState(getTempEmail() ?? "");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);
		try {
			const res = await registerPublic(firstName, lastName, email, phoneNumber);
			removeTempEmail();
			if (res.error !== null) {
				swal("Error!", res.error, "error");
				return;
			}

			callback(res.data);
		} catch (e) {
			swal("Error!", e.message, "error");
			return;
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="box p-3">
			<div
				style={{
					fontSize: "24px",
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				Lengkapi Data Diri
			</div>
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<label>Alamat Email</label>
					<input
						type="email"
						className="form-control"
						placeholder="Alamat Email..."
						value={email}
						maxLength={150}
						onChange={(e) => setEmail(e.target.value)}
						disabled={loading}
						required
					/>
				</div>
				<div
					className="mb-3"
					style={{
						fontSize: "12px",
						color: "#575757",
					}}
				>
					Gunakan Email Aktif untuk dapat melakukan absensi dan mendapatkan
					Sertifikat
				</div>
				<div className="form-group mb-3">
					<label>Nama Depan</label>
					<input
						type="text"
						className="form-control"
						value={firstName}
						maxLength={50}
						onChange={(e) => setFirstName(e.target.value)}
						disabled={loading}
						required
					/>
				</div>
				<div className="form-group">
					<label>Nama Belakang</label>
					<input
						type="text"
						className="form-control"
						maxLength={50}
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						disabled={loading}
						required
					/>
				</div>
				<div
					className="mb-3"
					style={{
						fontSize: "12px",
						color: "#575757",
					}}
				>
					Pastikan Nama sudah sesuai untuk digunakan pada Sertifikat
				</div>
				<div className="form-group mb-3">
					<label>Nomor Whatsapp</label>
					<input
						type="text"
						className="form-control"
						placeholder="Nomor Whatsapp..."
						value={phoneNumber}
						maxLength={50}
						onChange={(e) => setPhoneNumber(e.target.value)}
						disabled={loading}
						required
					/>
				</div>
				<div className="mt-4">
					<button
						style={{
							width: "100%",
						}}
						className="btn btn-default"
						type="submit"
						disabled={loading}
					>
						{loading ? <Loading /> : "Lanjut Daftar"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default PublicForm;
