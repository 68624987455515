import React, { useEffect } from "react";
import SetPassword from "../components/account/SetPassword";
import { Col, Row } from "react-bootstrap";

const SetPasswordScreen = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="container mb-5 mt-5">
			<Row>
				<Col
					xs={12}
					sm={12}
					md={{
						span: 8,
						offset: 2,
					}}
					lg={{
						span: 6,
						offset: 3,
					}}
				>
					<div className="box p-3">
						<h5
							style={{
								textAlign: "center",
								fontWeight: "bold",
							}}
						>
							Setel Kata Sandi
						</h5>
						<SetPassword />
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default SetPasswordScreen;
