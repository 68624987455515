import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { myList } from "./../../services/portfolio";
import { search } from "./../../services/license";
import {
	submit as submitExam,
	read,
	active,
} from "./../../services/license_exam";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import config from "../../helpers/config";
import Dropzone, { useDropzone } from "react-dropzone";
import Loading from "../Loading";
import LicenseExamBox from "./LicenseExamBox";

const LicenseExamForm = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const account = useSelector((state) => state.account);

	const [selectedLicense, setSelectedLicense] = useState(null);
	const [seletedPortfolios, setSelectedPortfolios] = useState([]);
	const [portfolios, setPortfolios] = useState(null);
	const [licenses, setLicenses] = useState(null);
	const [licenseType, setLicenseType] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState("online");
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const [recommendationFiles, setRecommendationFiles] = useState([]);
	const [dataRecommendationLetterPath, setDataRecommendationLetterPath] =
		useState("");
	const [
		uploadedDataRecommendationLetterPath,
		setUploadedDataRecommendationLetterPath,
	] = useState("");

	const [previousLicenseFiles, setPreviousLicenseFiles] = useState([]);
	const [dataPreviousLicensePath, setDataPreviousLicensePath] = useState("");
	const [uploadedDataPreviousLicensePath, setUploadedDataPreviousLicensePath] =
		useState("");

	const [straFiles, setStraFiles] = useState([]);
	const [dataStraPath, setDataStraPath] = useState("");
	const [uploadedDataStraPath, setUploadedDataStraPath] = useState("");

	const [resumeFiles, setResumeFiles] = useState([]);
	const [dataRuleResumePath, setDataRuleResumePath] = useState("");
	const [uploadedDataRuleResumePath, setUploadedDataRuleResumePath] =
		useState("");

	const [certificateFiles, setCertificateFiles] = useState([]);
	const [dataCertificatePath, setDataCertificatePath] = useState("");
	const [uploadedDataCertificatePath, setUploadedDataCertificatePath] =
		useState("");

	const [portfolioDetailFiles, setPortfolioDetailFiles] = useState([]);
	const [dataPortfolioDetailPath, setDataPortfolioDetailPath] = useState("");
	const [uploadedDataPortfolioDetailPath, setUploadedDataPortfolioDetailPath] =
		useState("");

	const fileToBase64 = (selectedFile, callback) => {
		let file = null;
		//Check File is not Empty
		if (selectedFile.length > 0) {
			// Select the very first file from list
			let fileToLoad = selectedFile[0];
			// FileReader function for read the file.
			let fileReader = new FileReader();
			// Onload of file read the file content
			fileReader.onload = function (fileLoadedEvent) {
				file = fileLoadedEvent.target.result;
				// Print data in console
				callback(file);
			};
			// Convert data to base64
			fileReader.readAsDataURL(fileToLoad);
		}
	};
	/* data recommendation */

	useEffect(() => {
		//get licenses
		async function getLicenses(token) {
			const res = await search(token);
			if (res.error === null) {
				setLicenses(res.data);
			} else {
				swal("Error", res.error, "error");
			}
		}

		//check member type
		if (
			account !== null &&
			typeof account.membership !== "undefined" &&
			account.membership !== null
		) {
			if (account.membership.type !== config.membershipType.MEMBER_PRO) {
				//show alert
				swal("Error", "Anda tidak memiliki akses ke halaman ini", "error");
				navigate("/");
			}
		}

		async function getResults() {
			setLoading(true);

			const result = await active(auth.token);
			if (result.error === null) {
				// check if has active exam
				const exams = result.data;
				console.log("debug", exams);
				const activeExam = exams.find((exam) => exam.reference_number === null);
				if (activeExam) {
					navigate("/license-exam/result/" + activeExam.id);
				}
			}

			setLoading(false);
		}

		getResults();
		getLicenses(auth.token);
	}, [account, auth]);

	const resetForm = () => {
		setSelectedLicense(null);
		setSelectedPortfolios([]);
		setPaymentMethod("online");
		setDataRecommendationLetterPath("");
		setUploadedDataRecommendationLetterPath("");
		setDataPreviousLicensePath("");
		setUploadedDataPreviousLicensePath("");
		setDataStraPath("");
		setUploadedDataStraPath("");
		setDataRuleResumePath("");
		setUploadedDataRuleResumePath("");
		setDataCertificatePath("");
		setUploadedDataCertificatePath("");
		setDataPortfolioDetailPath("");
		setUploadedDataPortfolioDetailPath("");

		setRecommendationFiles([]);
		setPreviousLicenseFiles([]);
		setStraFiles([]);
		setResumeFiles([]);
		setCertificateFiles([]);
		setPortfolioDetailFiles([]);
	};

	const submit = async (e) => {
		if (e !== null) {
			console.log(e);
			e.preventDefault();
		}

		//show confirm
		const confirm = await swal({
			title: "Apakah anda yakin?",
			text: "Anda akan mengirimkan data ini!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		});

		if (confirm) {
			setSubmitLoading(true);

			const res = await submitExam(
				auth.token,
				selectedLicense.id,
				dataRecommendationLetterPath,
				dataPreviousLicensePath,
				dataStraPath,
				dataRuleResumePath,
				dataCertificatePath,
				dataPortfolioDetailPath
			);
			if (res.error === null) {
				resetForm();

				navigate("/license-exam/result/" + res.data.id);
			} else {
				//show error
				swal("Error", res.error, "error");
			}

			setSubmitLoading(false);
		}
	};

	useEffect(() => {
		if (status !== null) {
			submit(null);
		}
	}, [status]);

	const selectLicense = (id) => {
		if (id !== "") {
			setSelectedLicense(licenses.find((l) => l.id === id));
		} else {
			setSelectedLicense(null);
		}
	};

	return (
		<form onSubmit={submit}>
			<Row>
				<Col sm={12} md={12}>
					<div className="">
						<label>Tipe Lisensi</label>
						<select
							className="form-select"
							onChange={(e) => selectLicense(e.target.value)}
						>
							<option value="">Pilih Lisensi</option>
							{licenses !== null &&
								licenses.map((l, i) => (
									<option key={i} value={l.id}>
										{l.name}
									</option>
								))}
						</select>
						{selectedLicense !== null && selectedLicense !== "" ? (
							<div
								className="box p-3 mt-3"
								style={{
									marginTop: "-10px !important",
								}}
							>
								{selectedLicense.description}
							</div>
						) : null}
						<div></div>
					</div>
				</Col>
			</Row>
			<div
				className="box mt-3 p-3"
				hidden={selectedLicense === null || selectedLicense === ""}
			>
				<h4>Formulir</h4>

				<div
					className="mt-3"
					style={{
						borderBottom: "1px solid #eee",
					}}
				>
					<label>Scan Surat Permohonan Rekomendasi (.pdf)</label>
					<div
						hidden={
							uploadedDataRecommendationLetterPath === null ||
							uploadedDataRecommendationLetterPath === ""
						}
					>
						<div className="text-center">
							<a
								style={{
									textDecoration: "none",
								}}
								href={uploadedDataRecommendationLetterPath}
								target="_blank"
								rel="noreferrer"
							>
								Lihat Surat Permohonan Rekomendasi
							</a>
						</div>
						<div className="alert alert-warning mt-2">
							Kosongkan Kolom ini apabila tidak ingin mengganti data.
						</div>
					</div>
					<Dropzone
						accept={["application/pdf"]}
						onDrop={(acceptedFiles) => {
							let error = null;
							acceptedFiles.forEach((file) => {
								if (file.type !== "application/pdf") {
									error = "File harus berformat PDF";
								}
							});

							if (error === null) {
								setRecommendationFiles(acceptedFiles);
								fileToBase64(acceptedFiles, setDataRecommendationLetterPath);
							} else {
								swal("Error", error, "error");
							}
						}}
						name="heroImage"
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span style={{ fontSize: ".8rem" }}>
									Drop file, or click to select file
								</span>
							</div>
						)}
					</Dropzone>
					<aside
						className="mt-2"
						hidden={
							recommendationFiles === null || recommendationFiles.length < 1
						}
					>
						<b>File yang dipilih:</b>
						<ul>
							{recommendationFiles.map((file) => (
								<li key={file.path}>
									<a target="_blank" href={file.path}>
										{file.path}
									</a>{" "}
									- {file.size / 1000} Kb
								</li>
							))}
						</ul>
					</aside>
				</div>

				<div
					className="mt-3"
					style={{
						borderBottom: "1px solid #eee",
					}}
					hidden={licenseType === config.licenseType.NEW}
				>
					<label>Scan IPTB/Lisensi Sebelumnya (.pdf)</label>
					<div
						hidden={
							uploadedDataPreviousLicensePath === null ||
							uploadedDataPreviousLicensePath === ""
						}
					>
						<div className="text-center">
							<a
								style={{
									textDecoration: "none",
								}}
								href={uploadedDataPreviousLicensePath}
								target="_blank"
								rel="noreferrer"
							>
								Lihat IPTB/Lisensi Sebelumnya
							</a>
						</div>
						<div className="alert alert-warning mt-2">
							Kosongkan Kolom ini apabila tidak ingin mengganti data.
						</div>
					</div>
					<Dropzone
						accept={["application/pdf"]}
						onDrop={(acceptedFiles) => {
							let error = null;
							acceptedFiles.forEach((file) => {
								if (file.type !== "application/pdf") {
									error = "File harus berformat PDF";
								}
							});

							if (error === null) {
								setPreviousLicenseFiles(acceptedFiles);
								fileToBase64(acceptedFiles, setDataPreviousLicensePath);
							} else {
								swal("Error", error, "error");
							}
						}}
						name="heroImage"
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span style={{ fontSize: ".8rem" }}>
									Drop file, or click to select file
								</span>
							</div>
						)}
					</Dropzone>
					<aside
						className="mt-2"
						hidden={
							previousLicenseFiles === null || previousLicenseFiles.length < 1
						}
					>
						<b>File yang dipilih:</b>
						<ul>
							{previousLicenseFiles.map((file) => (
								<li key={file.path}>
									<a target="_blank" href={file.path}>
										{file.path}
									</a>{" "}
									- {file.size / 1000} Kb
								</li>
							))}
						</ul>
					</aside>
				</div>

				<div
					className="mt-3"
					style={{
						borderBottom: "1px solid #eee",
					}}
				>
					<label>Scan STRA (.pdf)</label>
					<div
						hidden={
							uploadedDataRecommendationLetterPath === null ||
							uploadedDataRecommendationLetterPath === ""
						}
					>
						<div className="text-center">
							<a
								style={{
									textDecoration: "none",
								}}
								href={uploadedDataRecommendationLetterPath}
								target="_blank"
								rel="noreferrer"
							>
								Lihat STRA
							</a>
						</div>
						<div className="alert alert-warning mt-2">
							Kosongkan Kolom ini apabila tidak ingin mengganti data.
						</div>
					</div>
					<Dropzone
						accept={["application/pdf"]}
						onDrop={(acceptedFiles) => {
							let error = null;
							acceptedFiles.forEach((file) => {
								if (file.type !== "application/pdf") {
									error = "File harus berformat PDF";
								}
							});

							if (error === null) {
								setStraFiles(acceptedFiles);
								fileToBase64(acceptedFiles, setDataStraPath);
							} else {
								swal("Error", error, "error");
							}
						}}
						name="heroImage"
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span style={{ fontSize: ".8rem" }}>
									Drop file, or click to select file
								</span>
							</div>
						)}
					</Dropzone>
					<aside
						className="mt-2"
						hidden={straFiles === null || straFiles.length < 1}
					>
						<b>File yang dipilih:</b>
						<ul>
							{straFiles.map((file) => (
								<li key={file.path}>
									<a target="_blank" href={file.path}>
										{file.path}
									</a>{" "}
									- {file.size / 1000} Kb
								</li>
							))}
						</ul>
					</aside>
				</div>

				<div
					className="mt-3"
					style={{
						borderBottom: "1px solid #eee",
					}}
					hidden={licenseType === config.licenseType.CONVERSION}
				>
					<label>
						Sertifikat PKA 1 atau Strata 1 dan Strata 2 IAI Jakarta (Bagi
						anggota IAI non Jakarta) (.pdf)
					</label>
					<div
						hidden={
							uploadedDataCertificatePath === null ||
							uploadedDataCertificatePath === ""
						}
					>
						<div className="text-center">
							<a
								style={{
									textDecoration: "none",
								}}
								href={uploadedDataCertificatePath}
								target="_blank"
								rel="noreferrer"
							>
								Lihat Sertifikat
							</a>
						</div>
						<div className="alert alert-warning mt-2">
							Kosongkan Kolom ini apabila tidak ingin mengganti data.
						</div>
					</div>
					<Dropzone
						accept={["application/pdf"]}
						onDrop={(acceptedFiles) => {
							let error = null;
							acceptedFiles.forEach((file) => {
								if (file.type !== "application/pdf") {
									error = "File harus berformat PDF";
								}
							});

							if (error === null) {
								setCertificateFiles(acceptedFiles);
								fileToBase64(acceptedFiles, setDataCertificatePath);
							} else {
								swal("Error", error, "error");
							}
						}}
						name="heroImage"
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span style={{ fontSize: ".8rem" }}>
									Drop file, or click to select file
								</span>
							</div>
						)}
					</Dropzone>
					<aside
						className="mt-2"
						hidden={certificateFiles === null || certificateFiles.length < 1}
					>
						<b>File yang dipilih:</b>
						<ul>
							{certificateFiles.map((file) => (
								<li key={file.path}>
									<a target="_blank" href={file.path}>
										{file.path}
									</a>{" "}
									- {file.size / 1000} Kb
								</li>
							))}
						</ul>
					</aside>
				</div>

				<div className="mt-3">
					<label>
						Portfolio 1 Proyek (siteplan, denah tampak, potongan, foto
						konstruksi) Sesuai Golongan (.pdf)
					</label>
					<div
						hidden={
							uploadedDataPortfolioDetailPath === null ||
							uploadedDataPortfolioDetailPath === ""
						}
					>
						<div className="text-center">
							<a
								style={{
									textDecoration: "none",
								}}
								href={uploadedDataPortfolioDetailPath}
								target="_blank"
								rel="noreferrer"
							>
								Lihat File Portfolio
							</a>
						</div>
						<div className="alert alert-warning mt-2">
							Kosongkan Kolom ini apabila tidak ingin mengganti data.
						</div>
					</div>
					<Dropzone
						accept={["application/pdf"]}
						onDrop={(acceptedFiles) => {
							let error = null;
							acceptedFiles.forEach((file) => {
								if (file.type !== "application/pdf") {
									error = "File harus berformat PDF";
								}
							});

							if (error === null) {
								setPortfolioDetailFiles(acceptedFiles);
								fileToBase64(acceptedFiles, setDataPortfolioDetailPath);
							} else {
								swal("Error", error, "error");
							}
						}}
						name="heroImage"
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span style={{ fontSize: ".8rem" }}>
									Drop file, or click to select file
								</span>
							</div>
						)}
					</Dropzone>
					<aside
						className="mt-2"
						hidden={
							portfolioDetailFiles === null || portfolioDetailFiles.length < 1
						}
					>
						<b>File yang dipilih:</b>
						<ul>
							{portfolioDetailFiles.map((file) => (
								<li key={file.path}>
									<a target="_blank" href={file.path}>
										{file.path}
									</a>{" "}
									- {file.size / 1000} Kb
								</li>
							))}
						</ul>
					</aside>
				</div>
			</div>
			<div
				className="mt-5"
				hidden={selectedLicense === null || selectedLicense === ""}
			>
				<div className="box p-3 mb-3">
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div>
							<div
								style={{
									fontWeight: "800",
									fontSize: "17px",
									marginBottom: "8px",
								}}
							>
								Demo Ujian {selectedLicense?.name}
							</div>
							<div
								style={{
									color: "#575757",
									fontSize: "12px",
									display: "flex",
									alignItems: "center",
									gap: "4px",
									marginBottom: "8px",
								}}
							>
								<svg
									width="23"
									height="24"
									viewBox="0 0 23 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.70395 7V3M15.2209 7V3M6.76433 11H16.1605M4.8851 21H18.0398C19.0776 21 19.919 20.1046 19.919 19V7C19.919 5.89543 19.0776 5 18.0398 5H4.8851C3.84722 5 3.00586 5.89543 3.00586 7V19C3.00586 20.1046 3.84722 21 4.8851 21Z"
										stroke="#C4C4C4"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<div>3 September 2024 - 13 September 2024</div>
							</div>
							<div>
								Isi data diri pada Google Form dan kerjakan 10 soal yang akan
								ditanyakan
							</div>
						</div>
						<div>
							<button className="btn btn-default">Ikuti</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default LicenseExamForm;
