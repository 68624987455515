import React, { useEffect } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import IsExpired from "../components/account/IsExpired";
import LicenseExamForm from "../components/license_exams/LicenseExamForm";

const LicenseExamFormScreen = () => {
	//change title
	useEffect(() => {
		document.title = config.documentTitle + " - Formulir Ujian Lisensi";

		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="default-nav-mt">
			<IsExpired />

			<Container>
				<Row>
					<Col sm={12} md={12} lg={4} className="mb-4">
						<LeftMenuPanel activeIndex={10} />
					</Col>
					<Col sm={12} md={12} lg={8}>
						<LicenseExamForm />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LicenseExamFormScreen;
