import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import { setRedirectUrl } from "../redux/slicers/authSlice";
import swal from "sweetalert";

const PrivateRoute = ({ children }) => {
	let location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const auth = useSelector((state) => state.auth);
	const account = useSelector((state) => state.account);

	useEffect(() => {
		if (auth.isAuthenticated) {
			dispatch(setRedirectUrl(null));
		} else {
			//check path name
			if (
				location.pathname !== "/login" &&
				location.pathname !== "/" &&
				location.pathname !== "/set-password"
			) {
				dispatch(setRedirectUrl(location.pathname));
			}
		}
	}, [location, auth]);

	useEffect(() => {
		if (account.is_password_set === null) return;

		const pathName = location.pathname;

		if (!account.is_password_set && pathName !== "/set-password") {
			navigate("/set-password");
			return;
		}

		if (account.is_password_set && pathName === "/set-password") {
			swal("Perhatian", "Password sudah diatur", "warning");
			navigate("/dashboard");
			return;
		}
	}, [account, location, navigate]);

	return auth.isAuthenticated ? (
		children
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default PrivateRoute;
