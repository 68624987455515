import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
    showUserDetail
} from './../../redux/slicers/userSlice';

const Comment = (props) => {
    const comment = props.comment;

    const dispatch = useDispatch();

    const viewUserDetail = (id) => {
        dispatch(showUserDetail(id));
    }

    return (
        <div className="mb-3">
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <div className="avatar-container" style={{
                    border: 'none'
                }}>
                    <div className="avatar-frame">
                        <img src={comment.avatar} alt="" />
                    </div>
                </div>
                <div style={{
                    fontWeight: 'bold',
                }}>
                    {comment.name}
                </div>
            </div>
            <div>
                <div style={{
                    textAlign: 'left',
                }}>
                    <div onClick={() => viewUserDetail(comment.user_id)} style={{
                        fontSize: '10pt',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>

                    </div>
                    {comment.body}
                </div>
                <div style={{
                    fontSize: '14px',
                    marginTop: '10px',
                    color: '#AA272F',
                }}>
                    {moment(comment.created_at).format('DD MMMM YYYY')}
                </div>
            </div>
        </div>
    );
}

export default Comment;