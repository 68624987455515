import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { read, score } from "../../services/license_exam";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Loading from "../Loading";

const LicenseExamResult = () => {
	const params = useParams();
	const auth = useSelector((state) => state.auth);

	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [scoreValue, setScore] = useState(null);
	const [referenceNumber, setReferenceNumber] = useState("");
	const [dateFetch, setDateFetch] = useState("");
	const [license, setLicense] = useState("");

	useEffect(() => {
		if (!params.id || !auth) return;

		async function getResults() {
			setLoading(true);

			const result = await read(auth.token, params.id);
			if (result.error) {
				swal("Error!", result.error, "error");
				setLoading(false);
				return;
			}

			setLoading(false);
			setLicense(result.data.license);
			setScore(result.data.score);
			setReferenceNumber(result.data.reference_number);
		}

		getResults();
	}, [auth, params.id, dateFetch]);

	const submit = async (e) => {
		e.preventDefault();

		if (!referenceNumber) {
			swal("Error!", "Nomor referensi ujian harus diisi", "error");
			return;
		}

		setSubmitLoading(true);
		const result = await score(auth.token, params.id, referenceNumber);
		if (result.error) {
			swal("Error!", result.error, "error");
			setSubmitLoading(false);
			return;
		}
		setDateFetch(new Date());
		setSubmitLoading(false);

		setScore(result.data.score);
	};

	const examLink = useMemo(() => {
		if (!license) return "";

		const { name } = license;
		const lowerName = name.toLowerCase();

		if (lowerName === "lisensi a") return "https://forms.gle/VaFbJxuTdwGFyrwZ7";

		if (lowerName === "lisensi b") return "https://forms.gle/1yGJA6TZ4EQtPv5B7";

		if (lowerName === "lisensi c") return "https://forms.gle/viU2Lb79aaz2tCzv9";

		return "";
	}, [license]);

	if (loading) return <Loading />;

	return (
		<div className="box p-4 mb-3">
			<div
				style={{
					fontWeight: "800",
					fontSize: "17px",
					marginBottom: "8px",
				}}
			>
				Demo Ujian {license?.name}
			</div>
			<div
				style={{
					color: "#575757",
					fontSize: "12px",
					display: "flex",
					alignItems: "center",
					gap: "4px",
					marginBottom: "8px",
				}}
			>
				<svg
					width="23"
					height="24"
					viewBox="0 0 23 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.70395 7V3M15.2209 7V3M6.76433 11H16.1605M4.8851 21H18.0398C19.0776 21 19.919 20.1046 19.919 19V7C19.919 5.89543 19.0776 5 18.0398 5H4.8851C3.84722 5 3.00586 5.89543 3.00586 7V19C3.00586 20.1046 3.84722 21 4.8851 21Z"
						stroke="#C4C4C4"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div>3 September 2024 - 13 September 2024</div>
			</div>
			<div>
				<a href={examLink} target="_blank" rel="noreferrer">
					<button className="btn btn-default">Kerjakan Ujian</button>
				</a>
			</div>

			<form className="mt-5" onSubmit={submit}>
				<div>
					<label
						style={{
							fontWeight: "800",
						}}
					>
						Nomor Referensi Ujian
					</label>
					<input
						type="text"
						className="form-control"
						required
						maxLength={100}
						disabled={submitLoading}
						value={referenceNumber}
						onChange={(e) => setReferenceNumber(e.target.value)}
					/>
				</div>
				<div>
					<button className="btn btn-default mt-3" disabled={submitLoading}>
						{submitLoading ? <Loading /> : "Lihat Nilai"}
					</button>
				</div>
			</form>

			{scoreValue !== null && (
				<div className="mt-5">
					<div
						style={{
							fontWeight: "800",
							fontSize: "17px",
							marginBottom: "8px",
						}}
					>
						Nilai Anda:
					</div>
					<div
						style={{
							fontSize: "32px",
							color: "#337A43",
							fontWeight: "800",
						}}
					>
						{scoreValue}
					</div>
				</div>
			)}
		</div>
	);
};

export default LicenseExamResult;
