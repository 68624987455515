import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    submitComment,
    comments
} from './../../services/ebook';
import swal from "sweetalert";
import Comment from "./Comment";
import { Col, Row } from "react-bootstrap";

const Comments = (props) => {
    const ebookId = props.ebookId;
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [commentList, setCommentList] = useState([]);
    const [totalComment, setTotalComment] = useState("0");
    const [lastId, setLastId] = useState(null);
    const [body, setBody] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [loadCommentLoading, setLoadCommentLoading] = useState(false);
    const [isContainMore, setIsContainMore] = useState(true);

    const getComments = async (lastId) => {
        setLoadCommentLoading(true);

        const res = await comments(auth.token, ebookId, lastId);
        if (res.error === null) {
            setTotalComment(res.total);

            if (lastId !== null) {
                setCommentList(lastestComments => [...lastestComments, ...res.data]);
            } else {
                setCommentList(res.data);
            }

            if (res.data.length > 0) {
                setLastId(res.data[res.data.length - 1].id);
            }
            setIsContainMore(res.data.length > 0);
        } else {
            swal("Error!", res.error.message, "error");
        }

        setLoadCommentLoading(false);
    };

    useEffect(() => {
        getComments(null);
    }, []);

    // useEffect(() => {
    //     async function getComments(lastId) {
    //         setLoadCommentLoading(true);

    //         const res = await comments(auth.token, ebookId, lastId);
    //         if (res.error === null) {
    //             setTotalComment(res.total);

    //             if (lastId !== null) {
    //                 setCommentList(lastestComments => [...lastestComments, ...res.data]);
    //             } else {
    //                 setCommentList(res.data);
    //             }

    //             setIsContainMore(res.data.length > 0);
    //         } else {
    //             swal("Error!", res.error.message, "error");
    //         }

    //         setLoadCommentLoading(false);
    //     }

    //     getComments(lastId);
    // }, [lastId, isRefresh]);

    const submit = async (e) => {
        e.preventDefault();

        let errors = [];

        //validate data
        if (!auth.isAuthenticated) {
            errors.push("You must login to comment");
        }

        if (errors.length < 1) {
            //if body is empty
            if (body === null || body.trim() === '') {
                errors.push("Comment is required");
            }
        }

        if (errors.length > 0) {
            swal("Error!", errors.join("\n"), "error");
        } else {
            setSubmitLoading(true);

            //submit comment
            const res = await submitComment(auth.token, ebookId, body);

            setSubmitLoading(false);

            if (res.error === null) {
                //succes, reset body
                setBody('');

                // show swal
                swal('Terimakasih', 'Komentar anda akan kami tinjau terlebih dahulu');
            } else {
                swal("Error!", res.error, "error");
            }
        }
    }

    const loadMore = () => {
        //get last id
        getComments(lastId);
    }

    return (
        <Row>
            <Col xs={12} sm={12} md={8} lg={6}>
                <div hidden={!auth.isAuthenticated} style={{
                    marginBottom: '50px'
                }}>
                    <div>
                        <h4 style={{
                            color: '#C7B37F',
                            marginTop: '50px',
                            fontWeight: 'bold'
                        }}>Komentar</h4>
                    </div>
                    <div>
                        <h5 style={{
                            color: '#575757'
                        }}>Berikan komentar, saran dan masukan Anda</h5>
                    </div>
                    <div className="d-flex mt-3">
                        <form onSubmit={submit} style={{
                            width: '100%',
                        }}>
                            <textarea value={body} onChange={e => setBody(e.target.value)} disabled={submitLoading} maxLength={450} className="form-control" style={{
                                height: '121px',
                                border: '1px solid #767676',
                                borderRadius: '20px !important',
                            }} placeholder="Isi Komentar Anda.."></textarea>
                            <div style={{
                                color: '#575757'
                            }}>Komentar, saran dan masukan Anda akan ditinjau oleh Administrator terlebih dahulu</div>
                            <button type="submit" disabled={submitLoading} className="btn btn-default-outline" style={{
                                padding: '6px 100px'
                            }}>
                                {submitLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Bagikan Komentar'}
                            </button>
                        </form>
                    </div>
                </div>
                <b>{totalComment} Comments:</b>
                {
                    commentList.length > 0 ? (
                        <div className="mt-3">
                            {
                                commentList.map((comment, index) => (
                                    <Comment comment={comment} key={comment.id} />
                                ))
                            }
                        </div>
                    ) : null
                }

                {
                    loadCommentLoading ? (
                        <div>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </div>
                    ) : (
                        <>
                            <div hidden={!isContainMore}>
                                <a href="#!" onClick={() => loadMore()}>Lihat Komentar Sebelumnya</a>
                            </div>
                            <div hidden={isContainMore} className="text-muted text-center">
                                Tidak Ada Komentar
                            </div>
                        </>
                    )
                }
            </Col>
        </Row>
    );
}

export default Comments;